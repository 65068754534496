.footer {
    background-color: var(--black);
    padding: 30px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px 0;
    margin-top: auto;
    flex-shrink: 0;
}

.tinyText {
    color: var(--lightgrey);
    font-size: var(--fontsize-small);
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100px;
}

.menuTitle {
    color: var(--lightgrey);
    font-size: var(--fontsize-small);
}

.menuItem {
    color: var(--white);
    font-size: var(--fontsize-big);
    font-weight: 600;
    cursor: pointer;
}

.logoName {
    color: var(--white);
    font-size: var(--fontsize-humongous);
    font-weight: 800;
}

.footerRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.socialMediaIcon {
    cursor: pointer;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fontsize-big);
}

.buttonBox {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 10px;
}

.menuBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.copyrightBox {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.legalBox {
    width: 100%;
    align-self: end;
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
}

.socialMediaBox {
    width: 100%;
    align-self: end;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media (min-width: 768px) {
    .footer {
        padding: 30px 40px;
    }

    .buttonBox {
        width: 50%;
    }

    .menuBox {
        width: 50%;
        justify-content: end;
        gap: 20px;
    }

    .copyrightBox {
        width: unset;
        flex-direction: column;
        order: 1;
        gap: 0;
        align-items: start;
    }

    .legalBox {
        width: unset;
        margin-left: auto;
        justify-content: end;
        order: 2;
    }
    
    .socialMediaBox {
        width: fit-content;
        margin-left: 20px;
        order: 3;
    }
}