.navbar {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--black);
    width: 100%;
    height: 70px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 1px 0px 10px var(--grey);
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    transition: top 0.5s ease;
    z-index: 100;
    flex-shrink: 0;
}

.nav-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
    transform: scaleY(0);
    transition: transform 0.5s ease;
    transform-origin: top;
    position: fixed;
    top: 70px;
    left: 0;
    padding: 20px;
    background-color: var(--black);
    box-shadow: 0px 10px 10px -10px var(--grey);
}

.nav-search {
    width: 70%;
    padding: 15px 0;
    margin: 0 auto;
    height: 100%;
}

.nav-search-results {
    position: fixed;
    left: 10px;
    right: 10px;
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
}

.nav-button {
    cursor: pointer;
    visibility: visible;
}

.nav-option {
    position: relative;
    cursor: pointer;
    color: var(--white);
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: var(--fontsize-gigantic);
    justify-content: flex-start;
    align-items: center;
}

.nav-submenu {
    position: absolute;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    color: var(--black);
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 1px 0px 5px var(--grey);
    top: 40px;
    transform: scaleY(0);
    min-width: 225px;
    transition: transform 0.5s ease;
    transform-origin: top;
    visibility: hidden;
    gap: 10px;
}

.suboptionRow{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    font-size: var(--fontsize-medium);
}

.navIconBox{
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#preise{
    background-color: var(--light);
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-submenu.blocker {
    position: unset;
    visibility: hidden;
    width: 100%;
}

@media (min-width: 900px) {
    .nav-content {
        display: flex;
        width: unset;
        flex-direction: row;
        transform: scaleY(1);
        position: unset;
        padding: unset;
        align-items: center;
        box-shadow: unset;
        gap: 30px;
    }

    .nav-search {
        margin-left: 30px;
        width: calc(100vw - 720px);
        max-width: 400px;
    }

    .nav-search-results {
        position: absolute;
        width: 100%;
    }

    .nav-button {
        display: none;
    }

    .nav-option {
        height: 70px;
        transition: transform 0.5s ease;
        font-size: var(--fonsize-large);
        justify-content: center;
    }

    .nav-option:hover {
        transform: scale(1.05);
    }

    .nav-submenu {
        top: 70px;
    }

    .nav-submenu.blocker {
        display: none;
    }

    .nav-suboption {
        transition: transform 0.5s ease;
    }

    .nav-suboption:hover {
        transform: translateX(5px);
    }
}

.logo {
    height: 35px;
    width: auto;
}

@media (min-width: 768px) {
    .logo {
        height: 45px;
    }
}

.nav-download {
    padding: 10px 20px;
    background-color: var(--white);
    color: var(--black);
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
}

.submenu {
    background-color: var(--white);
    color: var(--black);
    padding: 10px 15px;
    border-radius: 5px;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 1px 0px 5px var(--grey);
}
  
.submenu-item {
    cursor: pointer;
}

.submenu-toggle {
    position: relative;
}

.submenu-toggle .submenu {
    position: absolute;
    top: 100%;
    left: 0;
}