@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

:root {
    --black: #000000;
    --dark: #090909;
    --darkgrey: #222222;
    --grey: #4b4b4b;
    --lightgrey: #9b9b9b;
    --light: #f1f1f1;
    --white: #ffffff;
    --blue: #0d6efd;
    --red: #f20000;
    --green: #4ab022;
    --orange: #faab17;
    --purple: #8b44fd;
    --yellow: #fdfd44;
}

:root {
    --fontsize-small: 0.75rem; /* 12px */
    --fontsize-regular: 0.8rem; /* 14px */
    --fontsize-medium: 0.9rem; /* 16px */
    --fontsize-large: 1rem; /* 18px */
    --fontsize-big: 1.15rem; /* 20px */
    --fontsize-huge: 1.2rem; /* 22px */
    --fontsize-massive: 1.25rem; /* 24px */
    --fontsize-gigantic: 1.3rem; /* 26px */
    --fontsize-enormous: 1.35rem; /* 30px */
    --fontsize-humongous: 1.4rem; /* 36px */
    --fontsize-outrageous: 1.45rem; /* 40px */
    --fontsize-yourmum: 2rem;
}

@media (min-width: 768px) {
    :root {
        --fontsize-small: 0.75rem; /* 12px */
        --fontsize-regular: 0.875rem; /* 14px */
        --fontsize-medium: 1rem; /* 16px */
        --fontsize-large: 1.125rem; /* 18px */
        --fontsize-big: 1.25rem; /* 20px */
        --fontsize-huge: 1.35rem; /* 22px */
        --fontsize-massive: 1.5rem; /* 24px */
        --fontsize-gigantic: 1.65rem; /* 26px */
        --fontsize-enormous: 1.875rem; /* 30px */
        --fontsize-humongous: 2.25rem; /* 36px */
        --fontsize-outrageous: 2.5rem; /* 40px */
        --fontsize-yourmum: 4rem;
    }
}

/* Background Color */

.b-white {
    background-color: var(--white);
}

.b-black {
    background-color: var(--black);
}

.b-dark {
    background-color: var(--dark);
}

.b-darkgrey {
    background-color: var(--darkgrey);
}

.b-grey {
    background-color: var(--grey);
}

.b-lightgrey {
    background-color: var(--lightgrey);
}

.b-light {
    background-color: var(--light);
}

.b-blue {
    background-color: var(--blue);
}

.b-red {
    background-color: var(--red);
}

.b-purple {
    background-color: var(--purple);
}

.b-orange {
    background-color: var(--orange);
}

.b-green {
    background-color: var(--green);
}

.b-yellow {
    background-color: var(--yellow);
}

/* Text Color */

.t-white {
    color: var(--white) !important;
}

.t-lightgrey {
    color: var(--lightgrey) !important;
}

.t-grey {
    color: var(--grey) !important;
}

.t-darkgrey {
    color: var(--darkgrey) !important;
}

.t-dark {
    color: var(--dark) !important;
}

.t-black {
    color: var(--black) !important;
}

.t-red {
    color: var(--red) !important;
}

.t-green {
    color: var(--green) !important;
}

.t-orange {
    color: var(--orange) !important;
}

.t-light {
    color: var(--light) !important;
}

.t-blue{
    color: var(--blue) !important;
}

/* Font Weight */

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: 800;
}

/* Font Size */

.small {font-size: var(--fontsize-small)}
.regular {font-size: var(--fontsize-regular)}
.medium {font-size: var(--fontsize-medium)}
.large {font-size: var(--fontsize-large)}
.big {font-size: var(--fontsize-big)}
.huge {font-size: var(--fontsize-huge)}
.massive {font-size: var(--fontsize-massive)}
.gigantic {font-size: var(--fontsize-gigantic)}
.enormous {font-size: var(--fontsize-enormous)}
.humongous {font-size: var(--fontsize-humongous)}
.outrageous {font-size: var(--fontsize-outrageous)}
.yourmum {font-size: var(--fontsize-yourmum)}


.shadow{
    box-shadow: 0 0 10px var(--lightgrey);
}

.footerHeader {
    font-size: var(--fontsize-humongous);
    font-weight: 600;
    text-align: center;
}

.footerContent {
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .footerContent {
        padding: 50px;
        gap: 50px;
        width: 70%;
    }
}

input {
    outline: none;
}

.fullPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 70px);
    justify-content: center;
}

.eventRowTitle {
    font-weight: 600;
    font-size: var(--fontsize-gigantic);
    color: var(--black);
    line-height: normal;
    margin-bottom: 5px;
}

.buttonFlex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.getApp {
    background-color: var(--white);
    border-radius: 50px;
    color: var(--black);
    font-weight: 600;
    padding: 10px 15px;
    width: fit-content;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: var(--fontsize-large);
}

@media (min-width: 768px) {
    .getApp {
        padding: 10px 30px;
    }
}

.circle {
    height: 40px;
    width: 40px;
    z-index: 1;
    background-color: var(--dark);
    font-size: var(--fontsize-big);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 1px 1px 5px var(--lightgrey);
}

.PlaceholderFeature {
    border-radius: 5px;
    object-fit: cover;
}

.featuresTitle {
    font-size: var(--fontsize-enormous);
    font-weight: 600;
    text-align: center;
    color: var(--black);
}

.placeholderRowEvents{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    border-radius: 5px;
    color: var(--grey);
    text-align: center;
    background-color: var(--light);
}

.featuresSubtitle{
    font-size: var(--fontsize-small);
    text-align: center;
    color: var(--lightgrey);
}

.quote {
    text-align: center;
    color: var(--black);
}

.pinMap{
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventSectionTitle {
    font-size: var(--fontsize-huge);
    font-weight: 600;
    margin-bottom: 0.8rem;
    color: var(--black);
}

.eventDetailBox{
    background-color: var(--light);
    padding: 15px;
    border-radius: 5px;
}

.Title{
    font-size: var(--fontsize-enormous);
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: var(--black);
}

.bigText {
    font-size: var(--fontsize-yourmum);
    font-weight: 800;
    line-height: initial;
}

.subText {
    color: var(--lightgrey);
    margin-bottom: 10px;
    font-size: var(--fontsize-regular);
}

.whiteSection {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 25px;
    gap: 75px;
    width: 100%;
}

.blackSection {
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 25px;
    gap: 30px;
}

.lightSection {
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 25px;
    gap: 75px;
}

.dashboardSection {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 75px 25px;
}

.disclaimerBig {
    font-size: var(--fontsize-humongous);
    font-weight: 600;
    text-align: center;
}

.disclaimerSmall {
    font-size: var(--fontsize-medium);
    color: var(--lightgrey);
    text-align: center;
}

.introOrganizer {
    padding: 40px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 50px;
}

.TextOrganizer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}

.featureBox {
    background-color: var(--white);
    border-radius: 10px;
}

.featureBoxPicture {
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}

.featureBoxText {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.featureBoxHeader {
    font-size: var(--fontsize-massive);
    color: var(--black);
}

.featureBoxDescription {
    font-size: var(--fontsize-medium);
    color: var(--lightgrey);
}

.threeBoxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.checkoutBlurImage{
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    object-fit: cover;
    width: 100%;
    height: 300px;
    filter: blur(50px);
    opacity: 0.33;
}

.uploadEventsBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    gap: 5px;
    background-color: var(--black);
    padding: 10px 15px;
    height: fit-content;
    cursor: pointer;
    color: var(--white);
    font-size: var(--fontsize-regular)
}

.iconUploadEvent{
    display: flex;
    font-size: var(--fontsize-small);
}

.eventAdresseMap{
    height: 25vh;
    width: 100%;
    border-radius: 5px;
}


@media (min-width: 768px) {
    .quote {
        width: 75%;
    }

    .whiteSection {
        gap: 125px;
    }

    .lightSection {
        gap: 125px;
    }

    .introOrganizer {
        padding: 75px 25px 50px 25px;
        min-height: calc(100vh - 70px);
        gap: 75px;
    }

    .TextOrganizer {
        width: 75%;
    }

    .uploadEventsBtn{
        font-size: var(--fontsize-large);
        padding: 10px 25px;
        
    }

    .iconUploadEvent{
        font-size: var(--fontsize-medium);
    }

    .subText {
        font-size: var(--fontsize-medium);
    }

    .PlaceholderFeature {
        border-radius: 10px;
    }
}