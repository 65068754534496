.NotFoundBox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    gap: 3rem;
    flex-direction: column;
    padding: 40px;
}

.HomeLink{
    padding: 10px 30px;
    border-radius: 500px;
    min-width: 200px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue);
    gap: 1rem;
    font-size: var(--fontsize-big);
}

.errorText {
    color: var(--white);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
    text-align: center;
}

.errorTextSmall {
    color: var(--lightgrey);
    font-size: var(--fontsize-large);
}

.errorBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}