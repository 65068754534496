
.cityName {
    font-size: var(--fontsize-yourmum);
    font-weight: 600;
    color: var(--black);
}

.webappContent {
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    color: var(--black);
    width: 100%;
    min-height: calc(100vh - 70px);
}

.itemPageContent {
    flex-grow: 1;
    overflow: hidden;
    z-index: 1;
}

.discoverTopRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (min-width: 768px) {
    .webappContent.itemPage {
        flex-direction: row;
        gap: 50px;
    }

    .itemPageContent {
        margin-top: 50px;
    }
}

@media (min-width: 1024px) {
    .webappContent {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (min-width: 1280px) {
    .webappContent {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@media (min-width: 1536px) {
    .webappContent {
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.filter {
    padding: 5px 25px;
    color: var(--white);
    background-color: var(--grey);
    border-radius: 30px;
}

.filterBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
}

.seeMore {
    color: var(--lightgrey);
    font-size: var(--fontsize-regular);
    cursor: pointer;
    text-align: right;
}

.titleBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.getAppBox {
    background: rgb(227,46,46);
    background: linear-gradient(0deg, rgba(227,46,46,1) 0%, rgba(253,187,45,1) 100%);
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.getAppTitle {
    font-size: var(--fontsize-humongous);
    color: var(--white);
    font-weight: 600;
    line-height: 1.2;
}

.getAppSubtitle {
    font-size: var(--fontsize-regular);
    color: var(--light);
}

.getAppText {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.getAppLeft {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
}

.getAppRight {
    display: none;
}

@media (min-width: 768px) {
    .getAppRight {
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: center;
        justify-content: flex-end;
        padding-top: 25px;
    }

    .eventContentBox {
        gap: 75px;
    }
}

.eventContentBox {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.image-box {
    position: relative;
    margin: -20px -20px 0 -20px;
}

.leftSideBox{
    margin: -20px -20px 0 -20px;
    padding: 20px 20px 0 20px;
    position: relative;
}

@media (min-width: 768px) {
    .image-box {
        margin: unset;
        /* flex-shrink: 0; */
        /* position: sticky; */
        /* top: 40px; */
        box-shadow: 1px 5px 5px var(--light);
        width: 500px;
    }

    .leftSideBox{
        margin: unset;
        padding: unset;
        position: sticky;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-shrink: 0;
        top: 40px;
        height: fit-content;
        flex-basis: 0;
        width: 500px;
    }
}
